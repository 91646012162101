<template>
  <v-container>
    <abtest-table v-bind="props" :update-route="true"></abtest-table>
  </v-container>
</template>
<script>

import AbtestTable from '@/components/AbtestTable'
import {abtestTable} from '@/tables'

export default {
  components: {
    AbtestTable
  },
  data () {
    return {
      props: abtestTable
    }
  }
}
</script>