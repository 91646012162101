<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="model"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        :error-messages="errorMessages || []"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu"
      v-model="model"
      full-width
      @click:minute="menu = false"
    ></v-time-picker>
  </v-menu>
</template>
<script>

export default {
  props: ["value", "label", "errorMessages"],
  data () {
    return {
      model: this.value,
      menu: false,
    }
  },
  watch: {
    model (v) {
      this.$emit('input', v)
    }
  }
}
</script>